import React from 'react';
import { Link } from 'gatsby';


export const Hero = () => {

  return (
    <div className="py-5" style={{ minHeight: "calc(100vh - 85px)" }}>
      <div className="container">
        <div className="hero">
          <div className="hero__wrap">
            <div className="hero__header">
              <h2 className="hero__lead ">
                Create a functional contact page that  <br />  will make your visitors always love writing to you.
              </h2>
              <p className="hero__text">
                Create a stunning contact page with a well detailed contact form using windframe in only few minutes.
                Access 1000+ editable template blocks you can easily put together in any form you wish to create the perfect looking contact page for your visitors</p>
              <div className="hero__buttons mb-4">
                <Link href="https://windframe.devwares.com" className="btn1 hero__btn btn__inverse">
                  <span className="btn__text">Create a contact page</span>
                </Link>
              </div>
            </div>
            <div className="hero__image">
              <video autoPlay muted loop className="feature__img">
                <source src={require('../../../components/images/windheader.mp4')} type="video/mp4" />
              </video>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
