import React, { useState } from 'react';
import Cookies from 'universal-cookie';
import { Link } from 'gatsby';

import logo from '../images/logo.png';
import AppContext, { UserContext } from '../AppContext';

const NavBar = props => {
  const { user, dark } = props;

  const [toggle, setToggle] = useState(false);
  const [expanded, toggleExpanded] = useState(false);

  const cookies = new Cookies();

  return (
    <AppContext>
      <UserContext.Consumer>
        {({ requestLogout }) => {
          return (
            <React.Fragment>
              <header className={'py-4 px-4 md:py-6' + (dark && ' bg-gray-900 text-gray-100')}>
                <div className="container mx-auto p-0">
                  <div className="flex items-center justify-between">
                    <div className="flex-shrink-0">
                      <Link to="/" className="flex rounded outline-none">
                        <img className="w-auto h-8" src={logo} alt="" />
                      </Link>
                    </div>

                    <div className="flex lg:hidden">
                      <button type="button" className={dark ? 'text-gray-100' : 'text-gray-900'}>
                        {!expanded ? (
                          <span onClick={() => toggleExpanded(!expanded)}>
                            <svg
                              className="w-7 h-7"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="1.5"
                                d="M4 6h16M4 12h16M4 18h16"
                              />
                            </svg>
                          </span>
                        ) : (
                          <span onClick={() => toggleExpanded(!expanded)}>
                            <svg
                              className="w-7 h-7"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M6 18L18 6M6 6l12 12"
                              />
                            </svg>
                          </span>
                        )}
                      </button>
                    </div>

                    <div className="hidden lg:flex lg:ml-12 lg:items-center lg:justify-center lg:space-x-6 xl:space-x-6">
                      <Link
                        to="/windframe/docs/index"
                        title=""
                        className={
                          'text-base font-bold transition-all duration-200 rounded focus:outline-none font-pj hover:text-opacity-50' +
                          (dark ? ' text-gray-100' : ' text-gray-900')
                        }
                      >
                        Docs
                      </Link>

                      <Link
                        href="/windframe/import"
                        title=""
                        className={
                          'text-base font-bold transition-all duration-200 rounded focus:outline-none font-pj hover:text-opacity-50' +
                          (dark ? ' text-gray-100' : ' text-gray-900')
                        }
                      >
                        Import
                      </Link>
                      <Link
                        href="/windframe/blocks"
                        title=""
                        className={
                          'text-base font-bold transition-all duration-200 rounded focus:outline-none font-pj hover:text-opacity-50' +
                          (dark ? ' text-gray-100' : ' text-gray-900')
                        }
                      >
                        Blocks
                      </Link>

                      <Link
                        to="/windframe/form-builder"
                        title=""
                        className={
                          'text-base font-bold transition-all duration-200 rounded focus:outline-none font-pj hover:text-opacity-50' +
                          (dark ? ' text-gray-100' : ' text-gray-900')
                        }
                      >
                        Form Builder
                      </Link>

                      <Link
                        to="/windframe/pricing"
                        title=""
                        className={
                          'text-base font-bold transition-all duration-200 rounded focus:outline-none font-pj hover:text-opacity-50' +
                          (dark ? ' text-gray-100' : ' text-gray-900')
                        }
                      >
                        Pricing
                      </Link>

                      <Link
                        to="/contact"
                        title=""
                        className={
                          'text-base font-bold transition-all duration-200 rounded focus:outline-none font-pj hover:text-opacity-50' +
                          (dark ? ' text-gray-100' : ' text-gray-900')
                        }
                      >
                        Support
                      </Link>
                      <Link
                        to="https://windframe.tolt.io/"
                        target="_blank"
                        title=""
                        className={
                          'text-base font-bold transition-all duration-200 rounded focus:outline-none font-pj hover:text-opacity-50' +
                          (dark ? ' text-gray-100' : ' text-gray-900')
                        }
                      >
                        Refer and earn
                      </Link>
                    </div>

                    {/* <div className="loop">
                      <a
                        href="https://www.producthunt.com/posts/windframe?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-windframe"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=303580&theme=light"
                          alt="Windframe - No code TailwindCSS page builder | Product Hunt"
                          style={{ width: '250px', height: '54px' }}
                        />
                      </a>
                    </div> */}

                    {user.loggedIn ? (
                      <ul className="hidden lg:flex nav navbar-nav">
                        <li className="dropdown relative">
                          <div
                            onClick={() => {
                              console.log('Clicked');
                              setToggle(!toggle);
                            }}
                            role="button"
                            className={
                              'inline-flex items-center justify-center px-6 py-2 text-base font-bold leading-7 transition-all duration-200 border-2 border-gray-900 rounded-xl hover:bg-gray-600 font-pj focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900' +
                              (dark ? ' text-gray-100 bg-gray-800' : ' text-gray-900 bg-gray-100')
                            }
                          >
                            <span>Welcome</span>
                            <span>
                              <svg
                                width="24px"
                                height="24px"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M5.16108 10.0731C4.45387 9.2649 5.02785 8 6.1018 8H17.898C18.972 8 19.5459 9.2649 18.8388 10.0731L13.3169 16.3838C12.6197 17.1806 11.3801 17.1806 10.6829 16.3838L5.16108 10.0731ZM6.65274 9.5L11.8118 15.396C11.9114 15.5099 12.0885 15.5099 12.1881 15.396L17.3471 9.5H6.65274Z"
                                  fill="currentColor"
                                  className="text-gray-300"
                                />
                              </svg>
                            </span>
                          </div>

                          {toggle && (
                            <ul
                              className={
                                'absolute w-56 z-10 rounded-lg shadow p-2 ' +
                                (dark ? 'bg-gray-800 text-gray-100' : 'bg-white text-black')
                              }
                              role="menu"
                            >
                              <li
                                className={
                                  'py-1 pl-2 pr-8 ' +
                                  (dark ? 'hover:bg-gray-700' : 'hover:bg-gray-200')
                                }
                              >
                                <Link to="/windframe/subscription/" className="link">
                                  Windframe
                                </Link>
                              </li>
                              <li
                                className={
                                  'py-1 pl-2 pr-8 ' +
                                  (dark ? 'hover:bg-gray-700' : 'hover:bg-gray-200')
                                }
                              >
                                <Link to="/app/downloads/" className="link">
                                  Downloads
                                </Link>
                              </li>
                              <li
                                className={
                                  'py-1 pl-2 pr-8 ' +
                                  (dark ? 'hover:bg-gray-700' : 'hover:bg-gray-200')
                                }
                              >
                                <Link to="/app/account-settings/" className="link">
                                  Account Settings
                                </Link>
                              </li>
                              <li
                                className={
                                  'py-1 pl-2 pr-8 ' +
                                  (dark ? 'hover:bg-gray-700' : 'hover:bg-gray-200')
                                }
                              >
                                <Link to="/app/change-password/" className="link">
                                  Change Password
                                </Link>
                              </li>

                              <li
                                className={
                                  'py-1 pl-2 pr-8 ' +
                                  (dark ? 'hover:bg-gray-700' : 'hover:bg-gray-200')
                                }
                              >
                                <a
                                  onClick={() => {
                                    requestLogout();
                                  }}
                                >
                                  <span style={{ cursor: 'pointer' }} className="link">
                                    Logout
                                  </span>
                                </a>
                              </li>
                            </ul>
                          )}
                        </li>
                      </ul>
                    ) : (
                      <div className="hidden lg:ml-auto lg:flex lg:items-center lg:space-x-6">
                        <a
                          href="https://windframe.devwares.com/sign-up"
                          target="_blank"
                          className="inline-flex items-center justify-center px-6 py-2 text-base font-bold leading-7 text-white transition-all duration-200 bg-gray-900 border border-transparent rounded-xl hover:bg-gray-600 font-pj focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
                          role="button" rel="noreferrer"
                        >
                          Sign up
                        </a>
                        <a
                          href="https://windframe.devwares.com/sign-in"
                          target="_blank"
                          className="inline-flex items-center justify-center px-6 py-2 text-base font-bold leading-7 text-gray-900 transition-all duration-200 bg-white border-2 border-gray-900 rounded-xl hover:bg-gray-600 font-pj focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
                          role="button" rel="noreferrer"
                        >
                          Sign in
                        </a>
                      </div>
                    )}
                  </div>
                  {expanded && (
                    <div className="lg:hidden flex flex-col py-2">
                      <Link
                        to="/windframe/docs/index"
                        title=""
                        className={
                          'text-base font-bold transition-all duration-200 rounded focus:outline-none font-pj hover:text-opacity-50' +
                          (dark ? ' text-gray-100' : ' text-gray-900')
                        }
                      >
                        Docs
                      </Link>

                      <Link
                        href="/windframe/import"
                        className={
                          'py-2 text-base font-bold transition-all duration-200 rounded focus:outline-none font-pj hover:text-opacity-50 ' +
                          (dark ? 'text-gray-100' : 'text-gray-900')
                        }
                      >
                        Import
                      </Link>
                      <Link
                        href="/windframe/blocks"
                        className={
                          'py-2 text-base font-bold transition-all duration-200 rounded focus:outline-none font-pj hover:text-opacity-50 ' +
                          (dark ? 'text-gray-100' : 'text-gray-900')
                        }
                      >
                        Blocks
                      </Link>

                      <Link
                        to="/windframe/form-builder"
                        className={
                          'py-2 text-base font-bold transition-all duration-200 rounded focus:outline-none font-pj hover:text-opacity-50 ' +
                          (dark ? 'text-gray-100' : 'text-gray-900')
                        }
                      >
                        Form Builder
                      </Link>

                      <Link
                        to="/windframe/pricing"
                        title=""
                        className={
                          'py-2 text-base font-bold transition-all duration-200 rounded focus:outline-none font-pj hover:text-opacity-50 ' +
                          (dark ? 'text-gray-100' : 'text-gray-900')
                        }
                      >
                        Pricing
                      </Link>

                      <Link
                        to="/blog"
                        title=""
                        className={
                          'py-2 text-base font-bold transition-all duration-200 rounded focus:outline-none font-pj hover:text-opacity-50 ' +
                          (dark ? 'text-gray-100' : 'text-gray-900')
                        }
                      >
                        Blog
                      </Link>
                      <Link
                        to="/contact"
                        className={
                          'py-2 text-base font-bold transition-all duration-200 rounded focus:outline-none font-pj hover:text-opacity-50 ' +
                          (dark ? 'text-gray-100' : 'text-gray-900')
                        }
                      >
                        Support
                      </Link>
                      {user.loggedIn ? (
                        <>
                          <Link
                            to="/app/downloads/"
                            title=""
                            className={
                              'py-2 pr-2.5 text-base font-bold transition-all duration-200 rounded focus:outline-none font-pj hover:text-opacity-50 ' +
                              (dark ? 'text-gray-100' : 'text-gray-900')
                            }
                          >
                            Downloads
                          </Link>
                          <Link
                            to="/app/account-settings/"
                            title=""
                            className={
                              'py-2 pr-2.5 text-base font-bold transition-all duration-200 rounded focus:outline-none font-pj hover:text-opacity-50 ' +
                              (dark ? 'text-gray-100' : 'text-gray-900')
                            }
                          >
                            Account Settings
                          </Link>
                          <Link
                            to="/app/change-password/"
                            title=""
                            className={
                              'py-2 pr-2.5 text-base font-bold transition-all duration-200 rounded focus:outline-none font-pj hover:text-opacity-50 ' +
                              (dark ? 'text-gray-100' : 'text-gray-900')
                            }
                          >
                            Change Password
                          </Link>
                        </>
                      ) : (
                        <>
                          <Link
                            to="/app2/signin"
                            title=""
                            className={
                              'py-2 pr-2.5 text-base font-bold transition-all duration-200 rounded focus:outline-none font-pj hover:text-opacity-50 ' +
                              (dark ? 'text-gray-100' : 'text-gray-900')
                            }
                          >
                            Sign In
                          </Link>
                          <Link
                            to="/app2/signup"
                            title=""
                            className={
                              'py-2 pr-2.5 text-base font-bold transition-all duration-200 rounded focus:outline-none font-pj hover:text-opacity-50 ' +
                              (dark ? 'text-gray-100' : 'text-gray-900')
                            }
                          >
                            Sign Up
                          </Link>
                        </>
                      )}
                    </div>
                  )}
                </div>
              </header>
            </React.Fragment>
          );
        }}
      </UserContext.Consumer>
    </AppContext>
  );
};

export default NavBar;
